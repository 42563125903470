// alert("hi from my_vue_js_component.js");
import MatestackUiVueJs from "matestack-ui-vuejs";

const myComponent = {
  mixins: [MatestackUiVueJs.componentMixin],
  template: MatestackUiVueJs.componentHelpers.inlineTemplate,
  data: () => {
    return {
      dynamicValue: 0,
    };
  },
  methods: {
    increaseValue() {
      this.dynamicValue++;
      MatestackUiVueJs.eventHub.$emit("route_status_update-all-route-02", {
        very_late_count: this.dynamicValue,
        late_count: 8,
        nominal_count: 15,
        early_count: this.dynamicValue,
        very_early_count: 16,
      });
    },
  },
};

export default myComponent;
