import MatestackUiVueJs from "matestack-ui-vuejs";

const generatedDefect = {
  mixins: [MatestackUiVueJs.componentMixin],
  template: MatestackUiVueJs.componentHelpers.inlineTemplate,

  data() {
    return {
      id: this.props["id"],
      uuid: this.props["uuid"],
      content: this.props["content"],
      isGenerating: false,
      isUpdated: false,
    };
  },

  computed: {},

  methods: {
    updateDefect(payload) {
      console.log("update Defect");
      console.log(payload);
      this.content = payload["content"];
      this.isGenerating = false;
      this.isUpdated = true;
    },
    startGenerating() {
      console.log("start generating...");
      this.isGenerating = true;
      this.isUpdated = false;
      setTimeout(() => {
        console.log("timer up");
        this.isGenerating = false;
        if (!this.isUpdated) {
          console.log("not updated");
          this.content = "Seems to be taking longer than expected...";
        }
      }, 15000);
    },
  },

  mounted() {
    console.log("mounted");
    console.log("id: " + this.id);
    console.log("uuid: " + this.uuid);
    MatestackUiVueJs.eventHub.$on(
      "generated-defect-updated",
      this.updateDefect,
    );
    MatestackUiVueJs.eventHub.$on(
      "generating-defect-content",
      this.startGenerating,
    );
  },
  beforeDestroy() {
    MatestackUiVueJs.eventHub.$off(
      "generated-defect-updated",
      this.updateDefect,
    );
    MatestackUiVueJs.eventHub.$off(
      "generating-defect-content",
      this.startGenerating,
    );
  },
};

export default generatedDefect;
