import MatestackUiVueJs from "matestack-ui-vuejs";
import axios from "axios"; // if required
// import MatestackUiVueJs from 'matestack-ui-core' # docs say this is removed

// Vue.component("mapbox-gl-map", {
const mapboxGlMap = {
  mixins: [MatestackUiVueJs.componentMixin],
  template: MatestackUiVueJs.componentHelpers.inlineTemplate,

  data() {
    return {
      id: undefined,
      organization_id: undefined,
      serial_number: undefined,
      center: undefined,
      zoom: undefined,
      map: undefined,
    };
  },

  methods: {
    saveMapSettings() {
      var level = this.map.getZoom();
      var center = this.map.getCenter();
      var pitch = this.map.getPitch();
      var bearing = this.map.getBearing();
      axios({
        method: this.props["map_settings_method"],
        url: this.props["map_settings_path"],
        data: { level: level, center: center, pitch: pitch, bearing: bearing },
      });
    },

    saveMapStyle(style) {
      axios({
        method: this.props["map_settings_method"],
        url: this.props["map_settings_path"],
        data: { style: style },
      });
    },

    setMapStyle(style) {
      this.map.setStyle(style);
      this.saveMapStyle(style);
    },

    onMoveEnd() {
      const self = this;
      this.saveMapSettings();
    },

    onZoomed(level) {
      const self = this;
      this.saveMapSettings();
    },

    onLoaded() {
      const self = this;
      var map = this.map;
      this.vehicles = {};
      this.vehicles_updated_at = undefined;
      // this.loadVehicles(this.map, this.addMarker, this.updatePositions);
      console.log("map style: ", this.props["map_style"]);
      MatestackUiVueJs.eventHub.$on("set_map_style", this.setMapStyle);
      MatestackUiVueJs.eventHub.$on(
        "updated_vehicle_positions",
        this.receiveUpdatedPositions,
      );
      console.log("organization_id: ", this.props["organization_id"]);
      MatestackUiVueJs.eventHub.$on(
        "updated_vehicle_positions-" + this.props["organization_id"],
        this.receiveUpdatedPositions,
      );

      // this.loadVehcilePollingEnabled = true;
      this.loadVehcilePollingEnabled = false;
      this.intervalQueueCount = 0;
      this.processingVehiclePositions = false;

      if (this.loadVehcilePollingEnabled) {
        this.counterInterval = setInterval(
          function () {
            if (this.intervalQueueCount == 0) {
              this.intervalQueueCount++;
              // console.log('loadVehicles...');
              // console.log('intervalQueueCount: ' + this.intervalQueueCount);
              this.loadVehicles(this.map, this.addMarker, this.updatePositions);
            } else {
              console.log("skipping loadVehicles...");
              console.log("intervalQueueCount: " + this.intervalQueueCount);
            }
          }.bind(this),
          500,
        );
      }
    },

    addMarker(vehicle) {
      // console.log('addMarker ' + vehicle['id']);
      // console.log('vehicle ' + vehicle);
      // console.log(id);

      const marker_element = document.createElement("div");
      marker_element.className = "text-center";
      const icon = document.createElement("i");
      const width = 10;
      const height = 10;
      // icon.className = 'text-center vehicle-marker fa fa-bus fa-2x ' + vehicle['schedule']['class'];
      icon.className = "text-center vehicle-marker fa fa-bus fa-2x ";
      icon.style.width = `${width}px`;
      icon.style.height = `${height}px`;
      icon.style.backgroundSize = "100%";

      // icon.addEventListener('click', function(e){
      //   MatestackUiVueJs.eventHub.$emit('show_vehicle_slideout', {vehicle_id: vehicle['id']})
      // })

      marker_element.appendChild(icon);

      const fleet_number = marker_element.appendChild(
        document.createElement("label"),
      );
      fleet_number.innerHTML = "";
      fleet_number.className = "fleet-number text-center";

      // const headsign = marker_element.appendChild(document.createElement('label'));
      // headsign.innerHTML = vehicle['headsign'];
      // headsign.className = 'headsign text-center';

      // Add markers to the map.
      const marker = new mapboxgl.Marker(marker_element).setLngLat([
        vehicle["lon"],
        vehicle["lat"],
      ]);

      this.vehicles[vehicle["id"]] = marker;
      marker.addTo(this.map);
    },

    moveToNewPosition(vehicle) {
      const coordinates = [vehicle["lon"], vehicle["lat"]];
      const marker = this.vehicles[vehicle["id"]];

      marker.setLngLat(coordinates);

      var a = vehicle["positioned_at_float"];
      var b = Date.now() / 1000;
      const diff = b - a;
      const seconds = diff;

      const marker_element = marker.getElement();
      const label = marker_element.getElementsByTagName("label")[0];
      label.innerHTML = seconds.toFixed(1);
    },

    receiveUpdatedPositions(data) {
      if (this.processingVehiclePositions) {
        console.log("skipping receiveUpdatedPositions...");
        return;
      }
      this.processingVehiclePositions = true;
      const payload = data.payload;
      this.vehicles_updated_at = payload["updated_at"];
      for (var i in payload["vehicles"]) {
        var id = i;
        if (!this.vehicles[id]) {
          // If there is no marker with this id yet, instantiate a new one.
          this.addMarker(payload["vehicles"][id]);
        } else {
          // If there is already a marker with this id, simply modify its position.
          this.moveToNewPosition(payload["vehicles"][id]);
        }
      }
      this.processingVehiclePositions = false;
    },

    updatePositions(payload) {
      console.log("updatePositions");
      this.vehicles_updated_at = payload["updated_at"];
      for (var i in payload["vehicles"]) {
        var id = i;
        if (!this.vehicles[id]) {
          // If there is no marker with this id yet, instantiate a new one.
          this.addMarker(payload["vehicles"][id]);
        } else {
          // If there is already a marker with this id, simply modify its position.
          this.moveToNewPosition(payload["vehicles"][id]);
        }
      }
    },

    loadVehicles(map, add_marker_function, update_positions_function) {
      console.log("loadVehicles...");
      // axios
      //   .get("/map/vehicle_positions?since=" + this.vehicles_updated_at)
      //   .then((res) => {
      //     update_positions_function(res.data);
      //     this.intervalQueueCount--;
      //   })
      //   .catch(function (error) {
      //     console.log(error);
      //     this.intervalQueueCount--;
      //   });
    },
  },

  mounted: function () {
    console.log("mounted");
    console.log("access_token: " + this.props["access_token"]);
    console.log(this.props);
    mapboxgl.accessToken = this.props["access_token"];
    this.map = new mapboxgl.Map({
      container: this.props["id"], // container ID
      style: this.props["map_style"], // style URL
      center: [this.props["center"][1], this.props["center"][0]], // starting position [lng, lat]
      zoom: this.props["zoom"],
      pitch: this.props["pitch"],
      bearing: this.props["bearing"],
    });

    let token = document.head.querySelector('meta[name="csrf-token"]');
    axios.defaults.headers.common["X-CSRF-TOKEN"] = token.content;

    var onZoomedFunction = this.onZoomed;
    // var onClickFunction = this.onClick;
    var onMoveEndFunction = this.onMoveEnd;
    var onLoadedFunction = this.onLoaded;

    this.map.on("zoomend", function () {
      onZoomedFunction(this.getZoom());
    });

    this.map.on("moveend", function () {
      onMoveEndFunction();
    });

    // this.map.on('click', function(e) {
    //   onClickFunction(e);
    // });

    this.map.on("load", function (e) {
      onLoadedFunction();
    });
  },

  beforeDestroy() {
    MatestackUiVueJs.eventHub.$off("set_map_style", this.setMapStyle);
    MatestackUiVueJs.eventHub.$off(
      "updated_vehicle_positions",
      this.receiveUpdatedPositions,
    );
    MatestackUiVueJs.eventHub.$off(
      "updated_vehicle_positions-" + this.organization_id,
    );
    clearInterval(this.counterInterval);
  },
};

export default mapboxGlMap;
