// alert('Hello from vite application.js!')
// To see this message, add the following to the `<head>` section in your
// views/layouts/application.html.erb
//
//    <%= vite_client_tag %>
//    <%= vite_javascript_tag 'application' %>
console.log("Vite ⚡️ Rails...");

// If using a TypeScript entrypoint file:
//     <%= vite_typescript_tag 'application' %>
//
// If you want to use .jsx or .tsx, add the extension:
//     <%= vite_javascript_tag 'application.jsx' %>

console.log(
  "Visit the guide for more information: ",
  "https://vite-ruby.netlify.app/guide/rails",
);

// Example: Load Rails libraries in Vite.
//
// import * as Turbo from '@hotwired/turbo'
// Turbo.start()
// import "@hotwired/turbo-rails"

// import "@hotwired/turbo-rails"
//
// import ActiveStorage from '@rails/activestorage'
// ActiveStorage.start()
//
// Import all channels.

// Example: Import a stylesheet in app/frontend/index.css
// import '~/index.css'

console.log("setting up matestack");
import MatestackUiVueJs from "matestack-ui-vuejs";
import { createApp } from "vue";
// import { createApp } from "vue/dist/vue.runtime.esm-bundler.js";
import { version } from "vue";

console.log(`===>>> Vue version : ${version}`);
// import TurbolinksAdapter from "vue-turbolinks";
import MatestackUiBootstrap from "matestack-ui-bootstrap";

const appInstance = createApp({});
// appInstance.use(TurbolinksAdapter);

MatestackUiBootstrap.registerComponents(appInstance);

import myComponent from "../../matestack/components/my_vue_js_component";
import multiplier from "../../matestack/components/maths/multiplier";
import routeStatusBar from "../../matestack/app/components/route_status/bar";
import generatedDefect from "../../matestack/app/components/generated_defect/defect";
import mapboxGlMap from "../../matestack/app/components/mapbox_gl/map";

appInstance.component("my-vue-js-component", myComponent);
appInstance.component("route-status-bar", routeStatusBar);
appInstance.component("generated-defect", generatedDefect);
appInstance.component("multiplier", multiplier);
appInstance.component("mapbox-gl-map", mapboxGlMap);

import "@rails/actioncable";
import "../channels/index";

document.addEventListener("DOMContentLoaded", () => {
  MatestackUiVueJs.mount(appInstance);
});
