import MatestackUiVueJs from "matestack-ui-vuejs";

const routeStatusBar = {
  mixins: [MatestackUiVueJs.componentMixin],
  template: MatestackUiVueJs.componentHelpers.inlineTemplate,

  data() {
    return {
      id: this.props["id"],
      title: this.props["title"],
      very_late_count: this.props["very_late_count"],
      late_count: this.props["late_count"],
      nominal_count: this.props["nominal_count"],
      early_count: this.props["early_count"],
      very_early_count: this.props["very_early_count"],
      width: this.props["width"],
      height: this.props["height"],
    };
  },

  computed: {
    total_count: function () {
      return (
        this.very_late_count +
        this.late_count +
        this.nominal_count +
        this.early_count +
        this.very_early_count
      );
    },
    very_late_percentage: function () {
      return (this.very_late_count / this.total_count) * 100;
    },
    late_percentage: function () {
      return (this.late_count / this.total_count) * 100;
    },
    nominal_percentage: function () {
      return (this.nominal_count / this.total_count) * 100;
    },
    early_percentage: function () {
      return (this.early_count / this.total_count) * 100;
    },
    very_early_percentage: function () {
      return (this.very_early_count / this.total_count) * 100;
    },
    unknown_percentage: function () {
      if (this.total_count == 0) {
        return 100;
      } else {
        return 0;
      }
    },

    very_late_x: function () {
      return 0;
    },
    late_x: function () {
      return this.very_late_x + this.very_late_percentage;
    },
    nominal_x: function () {
      return this.late_x + this.late_percentage;
    },
    early_x: function () {
      return this.nominal_x + this.nominal_percentage;
    },
    very_early_x: function () {
      return this.early_x + this.early_percentage;
    },
  },

  methods: {
    updateCounts(payload) {
      console.log("updateCounts");
      console.log(payload);
      this.very_late_count = payload["very_late_count"];
      this.late_count = payload["late_count"];
      this.nominal_count = payload["nominal_count"];
      this.early_count = payload["early_count"];
      this.very_early_count = payload["very_early_count"];
    },
  },

  mounted() {
    console.log("id: " + this.id);
    MatestackUiVueJs.eventHub.$on(
      "route_status_update-" + this.id,
      this.updateCounts,
    );
  },
  beforeDestroy() {
    MatestackUiVueJs.eventHub.$off(
      "route_status_update-" + this.id,
      this.updateCounts,
    );
  },
};

export default routeStatusBar;
