import MatestackUiVueJs from "matestack-ui-vuejs";

const multiplier = {
  mixins: [MatestackUiVueJs.componentMixin],
  template: MatestackUiVueJs.componentHelpers.inlineTemplate,
  data: () => {
    console.log("multiplier data");
    return {
      dynamicValue: 0,
    };
  },
  methods: {
    increaseValue() {
      if (this.dynamicValue == 0) {
        this.dynamicValue = 1;
      } else {
        this.dynamicValue = this.dynamicValue * 2;
      }
      MatestackUiVueJs.eventHub.$emit("route_status_update-all-route-02", {
        very_late_count: 15,
        late_count: 8,
        nominal_count: this.dynamicValue,
        early_count: 20,
        very_early_count: 6,
      });
      MatestackUiVueJs.eventHub.$emit("some_event");
    },
  },
};

export default multiplier;
